import { Moment } from 'moment';
import { RequestStatus } from '../../types/request';
import { AccountDetails } from '../Accounts/types';

export type CreateEvaluationType = {
  account_id: string;
  account: AccountDetails | null;
  evaluation_category: string;
  evaluation_name: string;
  closed_date: string | null | Moment;
  shareable_link: string;
  recipients: { notify_email: string }[];
  recipients_list: string[];
  target_responses: number;
  is_auto_email_reminder_active: boolean;
  days_before_close_date: number | null;
};

export interface UpdateEvaluationType extends CreateEvaluationType {
  evaluation_id: string;
}

export interface EvaluationCategory {
  id: string;
  name: string;
  created_at: string;
  last_updated_at: string;
  created_by: number;
  last_updated_by: number;
}

export interface GetEvaluationCategoryReducer {
  status: RequestStatus;
  evaluationCategories: EvaluationCategory[] | null;
}

export interface EvaluationCategoriesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: EvaluationCategory[];
}

export interface GetShareableLinkReducer {
  status: RequestStatus;
  link: { uuid: string };
}

export interface EvaluationListPayload {
  id?: string;
  account_id?: string;
  page?: number;
  search?: string;
  evaluation_status?: string;
}

export interface EvaluationListResponse {
  count: number;
  next: unknown;
  previous: unknown;
  results: EvaluationDetails[];
}

export interface EvaluationDetails {
  id: string;
  account_id: number;
  account_name?: string;
  evaluation_category: string;
  evaluation_version: number | null;
  evaluation_name: string;
  evaluation_status: string;
  closed_date: string;
  shareable_link: string;
  created_at: Date;
  created_by: string;
  target_responses: number;
  recipients_list: string[];
  encoded_evaluation_id: string;
  responses_received: number;
  report_link: string;
}

export interface PostEvaluationListReducer {
  status: RequestStatus;
  evaluationResponse: EvaluationListResponse | null;
}

export interface EvaluationSurveyPayload {
  [key: string]: string | number;
}

export interface PostEvaluationLinkPayload {
  email_list: string[];
  evaluation_id: string;
}

export enum EvaluationActions {
  PUBLISH = 'publish',
  REVIEW = 'review',
  UN_PUBLISH = 'un-publish',
  CLOSE = 'close',
  RE_OPEN = 're-open',
  DELETE = 'delete',
}

export enum EvaluationStatus {
  CLOSED = 'Closed',
  IN_PROGRESS = 'In Progress',
  RESULTS_REVIEW = 'Results Review',
  DRAFT = 'Draft',
}

export interface EvaluationOverallScore {
  'Strongly Agree': number;
  Agree: number;
  Neutral: number;
  Disagree: number;
  'Strongly Disagree': number;
}

export interface EvaluationQuestionCategory {
  id: string;
  category: string;
}

export interface EvaluationIndividualAnswerScore {
  question: string;
  score: number;
}

export interface ResultEvaluationResponse {
  overall_scores: EvaluationOverallScore;
  individual_answer_score: EvaluationIndividualAnswerScore[];
  average_evaluation_score: number;
  target_responses: number;
  responses_received: number;
}

export interface EvaluationQuestionsResponses {
  evaluation_questions_responses: EvaluationQuestionResponse[];
}

export interface EvaluationQuestionResponse {
  question: string;
  individual_answers: number[];
}

export interface EvaluationScoreHistoryResponse {
  evaluations_history: EvaluationCategoryScoreHistory[];
}

export interface EvaluationCategoryScoreHistory {
  evaluation_category_id: string;
  evaluation_category_name: string;
  evaluation_scores_over_time: EvaluationScoreHistory[];
}

export interface EvaluationScoreHistory {
  evaluation_id: string;
  evaluation_name: string;
  evaluation_completion_date: string;
  evaluation_average_score_categories: AverageCategoryScore[];
}

export interface AverageCategoryScore {
  question_category_id: string;
  question_category_name: string;
  average_response_score: number;
}

export interface EvaluationMCAnswersResponse {
  evaluation_id: string;
  questions_and_answers: QuestionAndMCAnswer[];
}

export interface EvaluationTextAnswersResponse {
  evaluation_id: string;
  questions_and_answers: QuestionAndTextAnswer[];
}

export interface QuestionAndMCAnswer {
  question_id: string;
  question_category: string;
  question_text: string;
  question_type: string;
  average_score: number;
}

export interface QuestionAndTextAnswer {
  question_id: string;
  question_category: string;
  question_text: string;
  question_type: string;
  answers: EvaluationAnswer[];
}

export interface EvaluationAnswer {
  answer_id: string;
  answer_text: string;
  submitted_by: string;
  submitted_at: string;
}

export interface EvaluationQuestionCategoriesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: EvaluationQuestionCategories[];
}

export interface EvaluationQuestionCategories {
  id: string;
  category: string;
}

export interface OverallScores {
  'Strongly Disagree': number;
  'Disagree': number;
  'Neutral': number;
  'Agree': number;
  'Strongly Agree': number;
}

export interface EvaluationQuestionCategory {
  evaluationQuestionCategoryId: string;
  overall_scores: OverallScores;
}

export interface EvaluationCategoryResponse {
  evaluationCategoryId: string;
  evaluationQuestionCategories: EvaluationQuestionCategory[];
}

export interface AggregateOverallScores {
  [key: string]: number;
}

export interface EvaluationImageUrlResponse {
  id: string;
  created_at: Date | null;
  last_updated_at: Date | null;
  img_url: string;
  evaluation: string;
  created_by: string;
  last_updated_by: string;
}