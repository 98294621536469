import React from 'react';
import {
  FormControl,
  TextField,
} from '@mui/material';
export default function TextEntryInput({
  question,
  formData,
  setFormData,
}: {
  question?: any;
  options?: any;
  formData?: any;
  setFormData?: any;
}) {
  const handleChange = (ev: string) => {
    setFormData({ ...formData, [question.id]: ev });
  };

  return (
    <FormControl sx={{ marginTop: '20px', marginLeft: '40px' }}>
      <TextField
        id="outlined-basic"
        variant="standard"
        multiline
        sx={{
          width: '800px',
        }}
        placeholder='Type your answer here'
        value={formData[question.id] || ''}
        onChange={(e) => handleChange(e.target.value)}
      />
    </FormControl>
  );
}
