import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useBranding } from '../../modules/Branding/reducers/getBranding';

const EcosystemIntelligence = () => {
  const [iframeUrl, setIframeUrl] = useState('');
  const branding = useBranding();
  const accessAllowed = branding?.company_name == "test" || branding?.company_name == "Hawaii"

  // TODO: To be updated to call database to fetch iframeUrl for that tenant
  useEffect(() => {
    if (accessAllowed) {
      setIframeUrl('https://coda.io/embed/k98lrYV_3v/_sujM5?viewMode=embedplay&hideSections=true');
    }
  }, [accessAllowed]);

  if (!accessAllowed) {
    // If access is not allowed, return null
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'calc(100vh - 140px)',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <iframe
        title="Coda Document Embed"
        src={iframeUrl}
        style={{
          border: 'none',
          width: '100%',
          height: 'calc(100% + 140px)',
          position: 'absolute',
          top: '-100px'
        }}
        allowFullScreen
      ></iframe>
    </Box>
  );
};
export default EcosystemIntelligence;
