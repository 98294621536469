/* eslint-disable indent */
import React, { useState } from 'react';
import { Box, Button, CircularProgress, Modal, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useParams } from 'react-router';
import EvaluationAnalysis from './EvaluationAnalysisScore';
import EvaluationAnalysisNotes from './EvaluationAnalysisScoreNotes';
import useGetEvaluationQuestionCategories from '../../hooks/useGetEvaluationQuestionCategory';
import { useAppSelector } from '../../../../redux/store';
import { EvaluationQuestionCategories } from '../../type';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import BarChartIcon from '@mui/icons-material/BarChart';
import IndividualResponseModal from '../EvaluationAnalysisContainer/IndividualResponseModal';

const EvaluationScoreAnalysisContainer = () => {
  const { id } = useParams();
  const evaluationQuestionCategoryReducer = useGetEvaluationQuestionCategories();
  const theme = useTheme();

  const evaluationQuestionCategories = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.getEvaluationQuestionCategoryReducer
      .evaluationQuestionCategories?.results;
  });

  // Set the initial tab value to the first category ID if categories are loaded
  const [value, setValue] = useState(
    evaluationQuestionCategories && evaluationQuestionCategories.length > 0
      ? evaluationQuestionCategories[0].id
      : ''
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  React.useEffect(() => {
    if (!value && evaluationQuestionCategories && evaluationQuestionCategories.length > 0) {
      setValue(evaluationQuestionCategories[0].id);
    }
  }, [evaluationQuestionCategories, value]);

  const [selectedCategory, setSelectedCategory] = useState('');

  React.useEffect(() => {
    const foundCategory = evaluationQuestionCategories?.find((category) => category.id === value);
    setSelectedCategory(foundCategory ? foundCategory.category : '');
  }, [value, evaluationQuestionCategories]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setCurrentIndex(0);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  React.useEffect(() => {
    evaluationQuestionCategoryReducer.getQuestionCategories();
  }, []);

  return (
    <div>
      {evaluationQuestionCategoryReducer.error ||
        evaluationQuestionCategoryReducer.loading ||
        !evaluationQuestionCategories ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
      ) : (
        <TabContext value={value}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TabList
              sx={{
                '& .MuiTab-root': {
                  '&.Mui-selected': {
                    color: '#00DB99',
                  },
                  textTransform: 'none',
                },
              }}
              onChange={handleChange}
              TabIndicatorProps={{ style: { backgroundColor: '#00DB99' } }}
              aria-label="lab API tabs example"
            >
              {evaluationQuestionCategories.map(
                (category: EvaluationQuestionCategories, index) => {
                  return (
                    <Tab
                      key={category.id}
                      label={category.category}
                      value={category.id}
                    />
                  );
                })}
              <Tab label="Evaluation Notes" value="notes" />
            </TabList>

            {value !== "notes" && (
              <Button
                endIcon={<BarChartIcon />}
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': { backgroundColor: theme.palette.primary.main, }
                }}
                onClick={handleOpenModal}
              >
                Individual Responses
              </Button>
            )}
          </Box>

          {evaluationQuestionCategories.map(
            (category: EvaluationQuestionCategories) => {
              return (
                <TabPanel
                  sx={{ padding: '0' }}
                  value={category.id.toString()}
                  key={category.id}
                >
                  <EvaluationAnalysis
                    id={id || ''}
                    evaluationQuestionCategoryId={category.id}
                  />
                </TabPanel>
              );
            },
          )}
          <TabPanel value="notes">
            <EvaluationAnalysisNotes
              id={id || ''}
            />
          </TabPanel>
        </TabContext>
      )}

      <IndividualResponseModal
        open={modalOpen}
        handleClose={handleCloseModal}
        evaluationId={id || ''}
        questionCategoryId={value || ''}
        questionCategory={selectedCategory || ''}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
};

export default EvaluationScoreAnalysisContainer;
