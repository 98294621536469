import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';

import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface EvaluationResultAnalysisResponse {
  id: string;
  is_deleted: boolean;
  challenges_threats: string[];
  root_causes: string;
  strengths: string;
  possible_offerings: string[];
  go_forward_plan: string;
  last_updated_at: string;
  evaluation: string;
  last_updated_by: string;
  question_category?: string;
  evaluation_status?: string;
  key_priorities?: string;
  development_opportunities?: string;
  implementation_details_and_timing?: string;
}

export interface EvaluationResultAnalysisResponseReducer {
  status: RequestStatus;
  evaluationResultResponse: EvaluationResultAnalysisResponse | null;
}

const initialState: EvaluationResultAnalysisResponseReducer = {
  status: RequestStatus.Init,
  evaluationResultResponse: null,
};

const getEvaluationResultAnalysisSlice = createSlice({
  name: '/getEvaluationResultAnalysisresponse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationAnalysisResults.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationAnalysisResults.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.evaluationResultResponse = action.payload;
    });
    builder.addCase(getEvaluationAnalysisResults.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationAnalysisResults = createAsyncThunk<
  EvaluationResultAnalysisResponse,
  { evaluation_id: string; question_category_id: string }
>(
  '/getEvaluationResults',
  async (
    params: { evaluation_id: string; question_category_id: string },
    thunkAPI,
  ) => {
    try {
      const response: AxiosResponse<EvaluationResultAnalysisResponse> =
        await axiosInstance.get(`${EndPoints.PUT_EVALUATION_RESULT_ANALYSIS}`, {
          params,
        });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export default getEvaluationResultAnalysisSlice.reducer;
