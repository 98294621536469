import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  AxiosResponse } from 'axios';
import { storeKey } from '../constants';
import { UpdateAccountPayload } from '../types';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

const initialState = {
  status: RequestStatus.Init,
};

const updateAccountSlice = createSlice({
  name: 'postUpdateAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAccount.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(updateAccount.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Account Updated Successfully!');
    });
    builder.addCase(updateAccount.rejected, (state) => {
      state.status = RequestStatus.Error;
      toast.error("Account can't be updated");
    });
  },
});

export const updateAccount = createAsyncThunk<string, UpdateAccountRequest>(
  `${storeKey}/postUpdateClient`,
  async (updateClientPayload: UpdateAccountRequest, { rejectWithValue }) => {
    try {
      const endpoint = `${EndPoints.PUT_UPDATE_ACCOUNT}${updateClientPayload.accountId}/`.replace(/\/+$/, '/');
      const response: AxiosResponse<string> = await axiosInstance.put(
        endpoint,
        updateClientPayload.updateClientRequest,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default updateAccountSlice.reducer;

export type UpdateAccountRequest = {
  updateClientRequest: UpdateAccountPayload;
  accountId: string;
};
