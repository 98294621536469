import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import { store, useAppSelector } from '../../../../redux/store';
import useGetCloudinarySignature from '../../hooks/useGetCloudinarySignature';
import useGetEvaluationImageUrl from '../../hooks/useGetEvaluationImageUrl';
import { useParams } from 'react-router-dom';
import usePostEvaluationImageUrl from '../../hooks/usePostEvaluationImageUrl';
import {
  CancelButton,
  SaveButton,
} from '../../../../modules/Accounts/CreateClient/CreateClient.styles';
import { EvaluationImageUrlResponse } from '../../type';

declare global {
  interface Window {
    cloudinary: any; // Use a more specific type if available
  }
}

interface CloudinarySignatureResponse {
  signature: string;
  timestamp: number;
}

const loadCloudinaryWidgetScript = (callback: () => void) => {
  const existingScript = document.getElementById('cloudinaryWidgetScript');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = "https://upload-widget.cloudinary.com/global/all.js";
    script.id = 'cloudinaryWidgetScript';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) callback();
};

const MyUploadComponent = () => {
  // State to store the uploaded image URL
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');
  const { loading, error, fetchSignature } = useGetCloudinarySignature();
  const { id } = useParams();
  const { getImageDetails } = useGetEvaluationImageUrl();
  const { postEvaluationImageDetailsResult } = usePostEvaluationImageUrl();
  const theme = useTheme();

  const evaluationImageDetails :EvaluationImageUrlResponse | null = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.getEvaluationImageUrlReducer
      .evaluationImageDetails;
  });

  React.useEffect(() => {
    loadCloudinaryWidgetScript(() => {
      // Script is loaded, you can now initialize the widget if needed
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      getImageDetails(id);
    }
  }, [id]);

  React.useEffect(() => {
    if (evaluationImageDetails?.img_url) {
      setUploadedImageUrl(evaluationImageDetails.img_url);
    }
  }, [evaluationImageDetails]);

  const openUploadWidget = async () => {
    try {
      const signatureData = await fetchSignature();

      if (signatureData) {
        window.cloudinary.createUploadWidget(
          {
            cloudName: process.env.REACT_APP_CLOUD_NAME,
            apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
            uploadSignature: signatureData.signature,
            uploadSignatureTimestamp: signatureData.timestamp,
            sources: ['local'],
            // Add more options here as needed
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              const imageUrl: string = result.info.secure_url as string;
              if (id) {
                postEvaluationImageDetailsResult({
                  evaluation: id,
                  img_url: imageUrl
                })
              }
              setUploadedImageUrl(imageUrl);
            }
          }
        ).open(); // Open the widget
      }
    } catch (err) {
      console.error('Failed to get signature from the backend.', err);
    }
  };

  const ButtonAndLinkContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column', // Stack button and link vertically
    alignItems: 'flex-start',
    marginLeft: '20px', // Add some space to the left of the button and link container
  });

  const ImageContainer = styled('div')({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'flex-start',
    margin: '20px 0',
  });
  
  const PreviewImage = styled('img')({
    maxWidth: '300px',
    maxHeight: '300px',
    objectFit: 'cover',
    border: '1px solid grey',
    margin: '0 10px', // Add horizontal margin to both sides of the image
  });

  const DownloadLink = styled('a')({
    display: 'block', // Ensures the link appears on a new line
    color: theme.palette.primary.main,
    textAlign: 'center', // Centers the link text below the button
    textDecoration: 'none', // Optionally removes the underline from the link
    marginTop: '8px', // Adds some space between the button and the link
    '&:hover': {
      textDecoration: 'underline', // Adds underline on hover
    },
  });

  return (
    <div>
      <ImageContainer>
        {uploadedImageUrl && <PreviewImage src={uploadedImageUrl} alt="Uploaded" />}
        <ButtonAndLinkContainer>
          <SaveButton type="button" onClick={openUploadWidget}>Upload File</SaveButton>
          <DownloadLink href="https://docs.google.com/presentation/d/1lKdSG6rrZHLX_2MuFF4QthFDrkAUlVrdiwwnrvj3-dg/edit?usp=drive_link" target="_blank">
            Click Here for Image Template Download
          </DownloadLink>
        </ButtonAndLinkContainer>
      </ImageContainer>
    </div>
  );
};

export default MyUploadComponent;