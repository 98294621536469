/* eslint-disable indent */
import React, { useEffect } from 'react';
import {
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  SvgIcon,
  Box,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  ContactFormGrid,
  Form,
  AccountCardHeading,
  AccountCardSubHeading,
  AccountPageCard,
  FormFieldContainer,
  SaveButton,
  CancelButton,
} from '../CreateClient/CreateClient.styles';
import translation from '../../../locales/en/translation.json';
import {
  FieldValues,
  useFieldArray,
  useForm,
  Controller,
} from 'react-hook-form';
import { AddBox } from '@mui/icons-material';
import { AccountContact, UpdateAccountPayload } from '../types';
import { ErrorMessage } from '../../../styles/Global.styles';
import TrashDIcon from '../../../assets/svg-components/TrashDIcon';
import useUpdateAccount from '../hooks/useUpdateAccount';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import useGetClient from '../hooks/useGetClient';
import { useAppSelector } from '../../../redux/store';
import { accountFormDefaultValues } from '../constants';
import { useFormLabelsTheme } from '../../../hooks/useFormLabelsTheme';

type AccountFormValues = FieldValues & UpdateAccountPayload;

function EditAccount() {
  const formLabelsTheme = useFormLabelsTheme();
  const form = useForm<AccountFormValues>({
    defaultValues: accountFormDefaultValues,
  });

  const navigate = useNavigate();
  const updateAccountReducer = useUpdateAccount();

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = form;

  const { fields, append, remove } = useFieldArray<AccountFormValues>({
    control,
    name: 'account_contacts',
  });

  const { id } = useParams();

  const { loading, error, getAccountById } = useGetClient();

  useEffect(() => {
    getAccountById(id || '');
  }, []);

  const accountData = useAppSelector((state) => {
    return state.allClientReducer.getClientReducer.client;
  });

  const defaultContact: AccountContact = {
    id: '',
    name: '',
    email: '',
    phone: '',
    location: '',
    role: '',
    is_primary_contact: false,
    account_id: undefined,
    created_by: undefined,
    created_at: undefined,
    last_updated_by: '',
  };

  useEffect(() => {
    if (accountData) {
      setValue('name', accountData.name);
      setValue('email', accountData.email);
      setValue('website', accountData.website);
      setValue('address1', accountData.address1);
      setValue('address2', accountData.address2);
      setValue('country', accountData.country);
      setValue('city', accountData.city);
      setValue('state', accountData.state);
      setValue('zipcode', accountData.zipcode);
      setValue('is_active', accountData.is_active);
      setValue(
        'date_of_establishment',
        accountData.date_of_establishment
          ? moment(accountData.date_of_establishment, 'YYYY-MM-DD')
          : null,
      );
      const updatedAccountContacts: AccountContact[] =
        accountData.account_contacts?.length > 0 ?
          accountData.account_contacts.map((eachAccount) => ({
            ...eachAccount,
            is_deleted: undefined,
            account_id: undefined,
            create_by: undefined,
            create_at: undefined,
            last_updated_by: '',
            last_updated_at: undefined,
          }))
          : [defaultContact];
      setValue(`account_contacts`, updatedAccountContacts);
    }
  }, [accountData]);

  const onSubmit = (data: UpdateAccountPayload) => {
    if (onePrimaryAddressExist(data.account_contacts)) {
      data.date_of_establishment = data.date_of_establishment
        ? moment(
          data.date_of_establishment as string,
          'MM/DD/YYYY',
          true,
        ).format('YYYY-MM-DD')
        : undefined;

      if (data.website) {
        // Check if it starts with http:// or https://, do nothing if it does
        if (!data.website.match(/^(http:\/\/|https:\/\/)/)) {
          // Check if it already starts with www., prepend http:// if it does
          if (data.website.startsWith('www.')) {
            data.website = `http://${data.website}`;
          } else {
            // For urls like test.com, prepend http://www.
            data.website = `http://www.${data.website}`;
          }
        }
      }

      //Remove Primary Contact Photo Field until we have a proper way f uploading using Cloudinary
      const processedAccountContacts = data.account_contacts.map(contact => {
        const { primary_contact_photo, ...rest } = contact;
        return rest;
      });

      // Use processedAccountContacts without primary_contact_photo for the update
      const updatedData = { ...data, account_contacts: processedAccountContacts };

      if (!updateAccountReducer.loading) {
        updateAccountReducer.editAccount({
          accountId: id || '',
          //Update with upadtedData instead of "data" to fix edit error arounod primary_contacct_photo
          updateClientRequest: updatedData,
        });
      }
    } else {
      toast.error('Only one primary address is acceptable.');
    }
  };

  function onePrimaryAddressExist(contactDetails: AccountContact[]) {
    let totalPrimaryContact = 0;
    contactDetails.forEach((data: AccountContact) => {
      if (data.is_primary_contact) {
        totalPrimaryContact++;
      }
    });
    return totalPrimaryContact <= 1;
  }


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AccountPageCard variant="outlined">
        <Grid container spacing={2}>
          <Grid item sm={1.5} xs={12}>
            <AccountCardHeading>{translation.general_info}</AccountCardHeading>
            <AccountCardSubHeading>
              {'Company Information'}
            </AccountCardSubHeading>
          </Grid>
          <Grid item sm={10} xs={12}>
            <ThemeProvider theme={formLabelsTheme}>
              <Grid sx={{ padding: '0px 42px' }} container>
                <Grid item sm={5.75} xs={10}>
                  <FormFieldContainer>
                    <FormLabel required>{translation.name}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.name}
                      variant="outlined"
                      size="small"
                      {...register(`name`, {
                        required: {
                          value: true,
                          message: translation.name_is_required,
                        },
                      })}
                    />
                    {errors.name && (
                      <ErrorMessage>
                        {errors.name.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.email}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.email}
                      variant="outlined"
                      size="small"
                      {...register(`email`)}
                    />
                    {errors.email && (
                      <ErrorMessage>
                        {errors.email.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.street1}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.street1}
                      variant="outlined"
                      size="small"
                      {...register(`address1`)}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.city}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.city}
                      variant="outlined"
                      size="small"
                      {...register(`city`)}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.country}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.country}
                      variant="outlined"
                      size="small"
                      {...register(`country`)}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.date_of_establishment}</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Controller
                        name="date_of_establishment"
                        control={control}
                        render={({ field }) => (
                          <DatePicker sx={{ height: '50px' }} {...field} />
                        )}
                      />
                    </LocalizationProvider>
                  </FormFieldContainer>
                </Grid>

                <Grid item sm={5.75} xs={10}>
                  <FormFieldContainer>
                    <FormLabel>{translation.status}</FormLabel>
                    <Select
                      size="small"
                      displayEmpty
                      defaultValue={true as any}
                      placeholder={translation.select_an_option}
                      {...register(`is_active`)}
                    >
                      <MenuItem value={true as any}>
                        {translation.active}
                      </MenuItem>
                      <MenuItem value={false as any}>
                        {translation.inactive}
                      </MenuItem>
                    </Select>
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.website}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.website}
                      variant="outlined"
                      size="small"
                      {...register(`website`, {
                        pattern: {
                          value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&\/\/=]*)$/,
                          message: translation.website_error_message,
                        },
                      })}
                    />
                    {errors.website && (
                      <ErrorMessage>
                        {errors.website.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.street2}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.street2}
                      variant="outlined"
                      size="small"
                      {...register(`address2`)}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.state}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.state}
                      variant="outlined"
                      size="small"
                      {...register(`state`)}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormLabel>{translation.zipcode}</FormLabel>
                    <TextField
                      id="outlined-basic"
                      placeholder={translation.zipcode}
                      variant="outlined"
                      size="small"
                      {...register(`zipcode`)}
                    />
                    {errors.zipcode && (
                      <ErrorMessage>
                        {errors.zipcode.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FormFieldContainer>
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>
        </Grid>
      </AccountPageCard>

      <AccountPageCard
        variant="outlined"
        sx={{
          marginTop: '10px',
        }}
      >
        <Grid container>
          <Grid item sm={1.5} xs={10}>
            <AccountCardHeading>{translation.contacts}</AccountCardHeading>
            <AccountCardSubHeading>
              {translation.company_contacts}
            </AccountCardSubHeading>
          </Grid>
          <Grid item sm={9.6} xs={10}>
            <ThemeProvider theme={formLabelsTheme}>
              {fields.map((field, index) => {
                return (
                  <ContactFormGrid container key={field.id}>
                    <Grid
                      sx={{ padding: '10px 0px  0px 42px' }}
                      item
                      sm={6}
                      xs={11}
                    >
                      <FormFieldContainer>
                        <FormLabel required>{translation.name}</FormLabel>
                        <TextField
                          id="outlined-basic"
                          placeholder={translation.name}
                          variant="outlined"
                          size="small"
                          {...register(`account_contacts.${index}.name`, {
                            required: {
                              value: true,
                              message: translation.name_is_required,
                            },
                          })}
                        />
                        {errors.account_contacts &&
                          errors.account_contacts[index]?.name && (
                            <ErrorMessage>
                              {errors.account_contacts[
                                index
                              ]?.name?.message?.toString()}
                            </ErrorMessage>
                          )}
                      </FormFieldContainer>

                      <FormFieldContainer>
                        <FormLabel>{translation.email}</FormLabel>
                        <TextField
                          id="outlined-basic"
                          placeholder={translation.email}
                          variant="outlined"
                          size="small"
                          {...register(`account_contacts.${index}.email`, {
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: translation.invalid_email,
                            },
                          })}
                        />
                        {errors.account_contacts &&
                          errors.account_contacts[index]?.email && (
                            <ErrorMessage>
                              {errors.account_contacts[
                                index
                              ]?.email?.message?.toString()}
                            </ErrorMessage>
                          )}
                      </FormFieldContainer>

                      <FormFieldContainer>
                        <FormLabel>{translation.phone_no}</FormLabel>
                        <TextField
                          id="outlined-basic"
                          placeholder={translation.phone_no}
                          variant="outlined"
                          size="small"
                          {...register(`account_contacts.${index}.phone`, {
                            maxLength: {
                              value: 26,
                              message: 'Length is more than 26.',
                            },
                            minLength: {
                              value: 10,
                              message: 'Length is less than 10.',
                            },
                          })}
                        />
                        {errors.account_contacts &&
                          errors.account_contacts[index]?.phone && (
                            <ErrorMessage>
                              {errors.account_contacts[
                                index
                              ]?.phone?.message?.toString()}
                            </ErrorMessage>
                          )}
                      </FormFieldContainer>
                    </Grid>

                    <Grid
                      item
                      sx={{ padding: '10px 42px  0px 0px' }}
                      sm={6}
                      xs={11}
                    >
                      <FormFieldContainer>
                        <FormLabel>{translation.contact_type}</FormLabel>
                        <Select
                          size="small"
                          displayEmpty
                          value={field[`is_primary_contact`]}
                          placeholder={translation.select_an_option}
                          inputProps={{
                            'aria-label': translation.select_an_option,
                          }}
                          {...register(
                            `account_contacts.${index}.is_primary_contact`,
                          )}
                        >
                          <MenuItem value={true as any}>
                            {translation.primary}
                          </MenuItem>
                          <MenuItem value={false as any}>
                            {translation.alternate}
                          </MenuItem>
                        </Select>
                      </FormFieldContainer>

                      <FormFieldContainer>
                        <FormLabel>{translation.role}</FormLabel>
                        <TextField
                          id="outlined-basic"
                          placeholder={translation.role}
                          variant="outlined"
                          size="small"
                          {...register(`account_contacts.${index}.role`)}
                        />
                        {errors.account_contacts &&
                          errors.account_contacts[index]?.role && (
                            <ErrorMessage>
                              {errors.account_contacts[
                                index
                              ]?.role?.message?.toString()}
                            </ErrorMessage>
                          )}
                      </FormFieldContainer>

                      <FormFieldContainer>
                        <FormLabel>{translation.location}</FormLabel>
                        <TextField
                          id="outlined-basic"
                          placeholder={translation.location}
                          variant="outlined"
                          size="small"
                          {...register(`account_contacts.${index}.location`)}
                        />
                        {errors.account_contacts &&
                          errors.account_contacts[index]?.location && (
                            <ErrorMessage>
                              {errors.account_contacts[
                                index
                              ]?.location?.message?.toString()}
                            </ErrorMessage>
                          )}
                      </FormFieldContainer>
                    </Grid>

                    {index === 0 ? (
                      <AddBox
                        sx={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          append({
                            ...accountFormDefaultValues.account_contacts[0],
                            is_primary_contact: false,
                          });
                        }}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={() => remove(index)}
                      >
                        <TrashDIcon />
                      </SvgIcon>
                    )}
                  </ContactFormGrid>
                );
              })}
            </ThemeProvider>
          </Grid>
        </Grid>
      </AccountPageCard>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          margin: '10px 30px',
          paddingBottom: '20px',
          maxWidth: '1512px',
        }}
      >
        <SaveButton type="submit">{translation.update}</SaveButton>
        <CancelButton onClick={() => navigate('/account-list')}>
          {translation.cancel}
        </CancelButton>
      </Box>
    </Form>
  );
}
export default EditAccount;
