/* eslint-disable indent */
import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  Grid,
  TextField,
  Button,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  FieldName,
  FieldValue,
  GridItemFieldName,
  GridItemFieldValue,
} from './EvaluationDetails.style';
import { LinkSharp } from '@mui/icons-material';
import { PieChart, Pie, Cell } from 'recharts';
import { EvaluationDetail } from '../reducers/getEvaluationDetail';
import BarcodeIcon from '../../../assets/svg-components/BarcodeIcon';
import InfoIcon from '../../../assets/svg-components/InfoIcon';
import IdentificationBadgeIcon from '../../../assets/svg-components/IdentificationBadgeIcon';
import HouseSimpleIcon from '../../../assets/svg-components/HouseSimpleIcon';
import MapPinIcon from '../../../assets/svg-components/MapPinIcon';
import PhoneIcon from '../../../assets/svg-components/PhoneIcon';
import AtIcon from '../../../assets/svg-components/AtIcon';
import ArrowBendRightUpIcon from '../../../assets/svg-components/ArrowBendRightUpIcon';
import { useLocation } from 'react-router-dom';
import { AccountInformation } from '../reducers/getAccount';
import usePostEvaluationLink from '../hooks/usePostEvaluationLink';
import moment from 'moment';
import { DownloadIcon } from '../../../assets/svg-components/SvgIcons';
import { EvaluationStatus } from '../type';
import { AccountContact } from '../../Accounts/types';
import { AccountSettingsUser } from '../../Accounts/AccountSettings/AccountSettings';
import { Tenant } from '../../../config/axios';
import { useBranding } from '../../Branding/reducers/getBranding';

const DistributionCard = ({ info }: { info: EvaluationDetail }) => {
  const location = useLocation();
  const baseUrl = window.location.href.replace(location.pathname, '/evaluate/');
  const copyEvaluationLinkToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.id = 'evaluation-link';
    tempInput.value = baseUrl + info?.shareable_link;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        Share Evaluation
      </Typography>
      {info.evaluation_status === EvaluationStatus.DRAFT && (
        <Typography
          variant="body1"
          sx={{
            color: '#B5B5C3',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            marginBottom: '14px',
          }}
        >
          <span style={{ color: 'red' }}>
            Anyone with this link will be able to view and complete the evaluation once it is published.
          </span>
        </Typography>
      )}
      {info.evaluation_status === EvaluationStatus.IN_PROGRESS && (
        <Typography
          variant="body1"
          sx={{
            color: '#B5B5C3',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            marginBottom: '14px',
          }}
        >
          Anyone with this link will be able to view and complete this evaluation.
        </Typography>
      )}
      <Typography
        sx={{
          marginTop: '14px',
          fontSize: '14px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        Anonymous Respondents
      </Typography>
      <Grid container gap={1} display={'flex'} alignItems={'center'}>
        <Grid item sm={8}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={baseUrl + info?.shareable_link || ''}
          />
        </Grid>
        <Grid item sm={3}>
          <Button
            size="small"
            sx={{
              backgroundColor: '#000',
              textTransform: 'none',
              maxHeight: '32px',
              color: '#fff',
              padding: '10px 20px',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#000',
                color: '#fff',
              },
            }}
            endIcon={<LinkSharp />}
            onClick={copyEvaluationLinkToClipboard}
          >
            Copy Link
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const EvaluationReport = ({ info }: { info: EvaluationDetail }) => {
  //const location = useLocation();
  //const generateReportReducer = useGetEvaluationReport();
  const branding = useBranding();
  const webinarDemo = branding?.company_name.toLowerCase().includes("demo");

  const backendReport = `api/v1/evaluate/report/?evaluation_id=${info.id}`;
  let reportGenerationUrl: string = (Tenant.domain as string) + backendReport;

  if (webinarDemo) {
    const baseUrl = window.location.origin;
    reportGenerationUrl = `${baseUrl as string}/pdfs/report.pdf`;
  }

  const copyReportLinkToClipboard = (download: boolean) => {
    const tempInput = document.createElement('input');
    tempInput.id = 'evaluation-report-link';
    if (webinarDemo) {
      tempInput.value = reportGenerationUrl;
    } else {
      tempInput.value = reportGenerationUrl + (download ? "&download=true" : '');
    }

    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        Share Report
      </Typography>
      <Box>
        <Typography
          sx={{
            marginTop: '14px',
            fontSize: '14px',
            fontWeight: 600,
          }}
          variant="h5"
          component="h2"
          gutterBottom
        >
          Online Report
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#B5B5C3',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            marginBottom: '14px',
          }}
        >
          Anyone with the link will be able to view the report
        </Typography>
        <Grid container gap={1} display={'flex'} alignItems={'center'}>
          <Grid item sm={8}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={reportGenerationUrl}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              size="small"
              sx={{
                backgroundColor: '#000',
                textTransform: 'none',
                maxHeight: '32px',
                color: '#fff',
                padding: '10px 20px',
                whiteSpace: 'nowrap',
                '&:hover': {
                  backgroundColor: '#000',
                  color: '#fff',
                },
              }}
              endIcon={<LinkSharp />}
              onClick={() => { copyReportLinkToClipboard(false) }}
            >
              Copy Link
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          sx={{
            marginTop: '14px',
            fontSize: '14px',
            fontWeight: 600,
          }}
          variant="h5"
          component="h2"
          gutterBottom
        >
          Print Report
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#B5B5C3',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            marginBottom: '14px',
          }}
        >
          Anyone with the link will be able to download the report
        </Typography>
        <Grid container gap={1} display={'flex'} alignItems={'center'}>
          <Grid item sm={8}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value= {webinarDemo ? reportGenerationUrl : `${reportGenerationUrl}&download=true`}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              size="small"
              sx={{
                backgroundColor: '#000',
                textTransform: 'none',
                maxHeight: '32px',
                color: '#fff',
                padding: '10px 20px',
                whiteSpace: 'nowrap',
                '&:hover': {
                  backgroundColor: '#000',
                  color: '#fff',
                },
              }}
              endIcon={<LinkSharp />}
              onClick={() => { copyReportLinkToClipboard(true) }}
            >
              Copy Link
            </Button>
          </Grid>
          <Button
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
              textTransform: 'none',
              color: '#000',
              marginLeft: '0',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#000',
              },
            }}
            onClick={() => {
              const url = webinarDemo ? '/pdfs/report.pdf' : `${reportGenerationUrl}&download=true`;
              window.open(url, '_blank');
            }}
          >
            <DownloadIcon />
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

const ResponsesGraphCard = ({ info }: { info: EvaluationDetail }) => {
  //Avoid Divide by Zero by changing target of 0 to 1 in chart calculations
  const targetResponseAmount = info.target_responses <= 0 ? 1 : info.target_responses;

  //Safety check to avoid negative resonses received
  const receivedResponseAmount = info.responses_received < 0 ? 0 : info.responses_received;

  const percentResponseRate = Math.round(receivedResponseAmount / targetResponseAmount * 100);

  //Total value needs to add to 100. Take 100 - PercentResponseRate. Okay if Target becomes negative here because it just doesn't show up which is desired so same as 0
  const data = [
    { name: 'Target', value: 100 - percentResponseRate },
    { name: 'Received', value: percentResponseRate },
  ];
  const COLORS = ['#95A4FC', '#BAEDBD'];

  return (
    <Box
      sx={{
        width: '100%',
        paddingBottom: '20px',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        Responses
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} position={'relative'}>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <Box
            sx={{
              height: '210px',
            }}
          >
            <PieChart
              width={400}
              height={400}
              style={{ position: 'absolute', top: '-80px' }}
            >
              <Pie
                data={data}
                cx={120}
                cy={200}
                startAngle={0}
                endAngle={360}
                cornerRadius={50}
                innerRadius={40}
                outerRadius={70}
                stroke="#fff"
                strokeWidth={5}
                fill="#8884d8"
                paddingAngle={-15}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            {/* </ResponsiveContainer> */}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              position: 'absolute',
              bottom: '-25px',
              left: '30%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: '0px',
              }}
            >
              <Typography variant="h6" component="h6" gutterBottom>
                {percentResponseRate}%
              </Typography>
              <IconButton
                disabled
                sx={{
                  position: 'relative',
                  top: '-10px',
                  left: '-5px',
                }}
              >
                <ArrowBendRightUpIcon width={25} height={25} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid container spacing={2}>
            <GridItemFieldName item xs={6} sm={6} sx={{ marginTop: '10px' }}>
              <Box
                sx={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  // backgroundColor: '#95A4FC',
                  backgroundColor: '#BAEDBD',
                  float: 'left',
                  marginRight: '5px',
                  position: 'relative',
                }}
              ></Box>
              <FieldName>Received</FieldName>
            </GridItemFieldName>
            <GridItemFieldValue item xs={6} sm={6}>
              <FieldValue> {info.responses_received}</FieldValue>
            </GridItemFieldValue>
            <GridItemFieldName item xs={6} sm={6}>
              <Box
                sx={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#C6C7F8',
                  float: 'left',
                  marginRight: '5px',
                  position: 'relative',
                }}
              ></Box>
              <FieldName>Target</FieldName>
            </GridItemFieldName>
            <GridItemFieldValue item xs={6} sm={6}>
              <FieldValue> {info.target_responses} </FieldValue>
            </GridItemFieldValue>
            <GridItemFieldName item xs={6} sm={6}>
              <Box
                sx={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#000',
                  float: 'left',
                  marginRight: '5px',
                  position: 'relative',
                }}
              ></Box>
              <FieldName>Close Date</FieldName>
            </GridItemFieldName>
            <GridItemFieldValue item xs={6} sm={6}>
              <FieldValue>
                {moment(info?.closed_date).format('MMM DD, YYYY')}
              </FieldValue>
            </GridItemFieldValue>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const GeneralInfoCard = ({
  info,
  accountInfo,
  accountUserData
}: {
  info: EvaluationDetail;
  accountInfo: AccountInformation;
  accountUserData: AccountSettingsUser[];
}) => {

  return (
    <Box
      sx={{
        marginLeft: '10px',
        backgroundColor: '#F7F9FB',
        padding: '30px 20px',
        borderRadius: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        General Info
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <BarcodeIcon width={20} height={20} />
            </IconButton>
            <FieldName> Evaluation ID </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue> {info?.encoded_evaluation_id || 'N/A'} </FieldValue>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <InfoIcon width={20} height={20} />
            </IconButton>
            <FieldName> Version </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue> {info?.evaluation_version || 'N/A'} </FieldValue>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <IdentificationBadgeIcon width={20} height={20} />
            </IconButton>
            <FieldName> Contact </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue>
              {accountInfo?.account_contacts?.find(
                (contact: AccountContact) => contact.is_primary_contact,
              )?.name || 'N/A'}
            </FieldValue>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <HouseSimpleIcon width={20} height={20} />
            </IconButton>
            <FieldName> Company </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue> {accountInfo?.name} </FieldValue>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <MapPinIcon width={20} height={20} />
            </IconButton>
            <FieldName> Location </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <Tooltip title={accountInfo.address1} placement="top-start">
              <FieldValue
                sx={{
                  maxWidth: '200px',
                }}
              >
                {accountInfo.address1}
              </FieldValue>
            </Tooltip>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <PhoneIcon width={20} height={20} />
            </IconButton>
            <FieldName> Phone </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue>
              {accountInfo?.account_contacts?.find(
                (contact: AccountContact) => contact.is_primary_contact,
              )?.phone || 'N/A'}
            </FieldValue>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <AtIcon width={20} height={20} />
            </IconButton>
            <FieldName> Email </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue>{accountInfo.email}</FieldValue>
          </GridItemFieldValue>
          <GridItemFieldName item xs={6} sm={6}>
            <IconButton disabled>
              <IdentificationBadgeIcon width={20} height={20} />
            </IconButton>
            <FieldName> Account Executive </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sm={6}>
            <FieldValue>
              {' '}
              {accountUserData?.find(
                (contact: AccountSettingsUser) => contact.role === "account_executive",
              )?.fullname || 'N/A'}
            </FieldValue>
          </GridItemFieldValue>
        </Grid>
      </Box>
    </Box>
  );
};

const DirectRecipientCard = ({ info }: { info: EvaluationDetail }) => {
  const { postSurveyLink } = usePostEvaluationLink();
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: '40px',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        Direct Recipient
      </Typography>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
              }}
              variant="h6"
              component="h6"
              gutterBottom
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              {info?.recipients_list.map((recipient, i) => {
                return (
                  <React.Fragment key={i}>
                    <Grid item xs={12} sm={8}>
                      <Tooltip title={recipient} placement="top-start">
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            minWidth: '205px',
                          }}
                          variant="body1"
                        >
                          {recipient || ''}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {info.evaluation_status === EvaluationStatus.IN_PROGRESS && (
                        <Button
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            textTransform: 'none',
                            borderRadius: '6px',
                            padding: '4px 12px',
                            fontSize: '14px',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: theme.palette.primary.main,
                              boxShadow: 'none',
                            },
                          }}
                          onClick={() => {
                            postSurveyLink({
                              email_list: [recipient],
                              evaluation_id: info.id,
                            });
                          }}
                          variant="contained"
                        >
                          Resend
                        </Button>
                      )}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const EvaluationDetails = (prop: {
  evaluationDetail: EvaluationDetail;
  accountInformation: AccountInformation;
  accountUserData: AccountSettingsUser[];
}) => {
  const { evaluationDetail, accountInformation, accountUserData } = prop;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {evaluationDetail && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <GeneralInfoCard
                accountInfo={accountInformation && accountInformation}
                info={evaluationDetail}
                accountUserData={accountUserData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: '#F7F9FB',
                      padding: '30px 20px',
                      borderRadius: '16px',
                    }}
                  >
                    <ResponsesGraphCard info={evaluationDetail} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(evaluationDetail.evaluation_status !== EvaluationStatus.RESULTS_REVIEW && evaluationDetail.evaluation_status !== EvaluationStatus.CLOSED)
                    && (
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <DistributionCard info={evaluationDetail} />
                        <DirectRecipientCard info={evaluationDetail} />
                      </Box>
                    )}
                </Grid>
                {evaluationDetail &&
                  evaluationDetail.evaluation_status ===
                  EvaluationStatus.RESULTS_REVIEW && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <EvaluationReport info={evaluationDetail} />
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EvaluationDetails;