import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { getAccountSetting } from '../reducers/getAccountSetting';

const useAccountSettingsUsers = (id: string) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAccountSettingsUsers = async () => {
    try {
      const resultAction = await dispatch(getAccountSetting(id));
      if (getAccountSetting.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    getAccountSettingsUsers();
  }, []);

  return { loading, error, getAccountSettingsUsers };
};

export default useAccountSettingsUsers;
