/* eslint-disable indent */

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from '@mui/material';

import {
  FieldName,
  FieldValue,
  GridItemFieldName,
  GridItemFieldValue,
} from '../../EvaluationDetails/EvaluationDetails.style';
import { PieChart, Pie, Cell } from 'recharts';
import { EvaluationTextAnswersResponse, EvaluationMCAnswersResponse, ResultEvaluationResponse } from '../../type';
import {
  CustomButton,
  Typography10px400,
  Typography12px400,
  Typography14px600,
} from '../../../../styles/Global.styles';
import StaticLikertScale from './StaticLikertScale';
import { useForm, FieldValues, Controller } from 'react-hook-form';
import { EvaluationResultPayload } from '../../reducers/putEvaluationResultAnalysis';
import { EvaluationResultAnalysisResponse } from '../../reducers/getEvaluationResultAnalysis';
import translation from '../../../../locales/en/translation.json';
import translate from '../../../../locales/translate';
import { useAppSelector } from '../../../../redux/store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import usePutEvaluationResultAnalysis from '../../hooks/usePutEvaluationResultAnalysis';
import useGetEvaluationAnalysisResults from '../../hooks/useGetEvaluationAnalysisResult';
import usePostEvaluationResultAnalysis from '../../hooks/usePostEvaluationResultAnalysis';
import useGetEvaluationQuestionCategories from '../../hooks/useGetEvaluationQuestionCategory';
import usePostEvaluationMCAnswers from '../../hooks/usePostEvaluationMCAnswers';
import usePostEvaluationTextAnswers from '../../hooks/usePostEvaluationTextAnswers';

const placeholderText = {
  color: '#B5B5C3',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const innerText = {
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const EvaluationAnalysis = (prop: {
  id: string;
  evaluationQuestionCategoryId: string;
}) => {
  const usePutEvaluationResultAnalysisReducer = usePutEvaluationResultAnalysis();
  const { postResultAnalysisSurvey } = usePostEvaluationResultAnalysis();
  const evaluationQuestionCategoryReducer = useGetEvaluationQuestionCategories();
  const useGetEvaluationAnalysisResultsReducer = useGetEvaluationAnalysisResults();
  const { postEvaluationResponseMCAnswers } = usePostEvaluationMCAnswers();
  const { postEvaluationResponseTextAnswers } = usePostEvaluationTextAnswers();

  const evaluationAnalysisResponse: ResultEvaluationResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationResultAnalysisReducer
        .resultEvaluationResponse;
    });

  const evaluationMCAnswers: EvaluationMCAnswersResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationMCAnswersReducer
        .evaluationMCAnswersResponse;
    });

  const evaluationTextAnswers: EvaluationTextAnswersResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationTextAnswersReducer
        .evaluationTextAnswersResponse;
    });

  const [rowsOfData, setRowOfData] = React.useState<
    { key: string; value: number }[]
  >([]);

  const COLORS = ['#BAEDBD', '#C6C7F8', '#000', '#F0BB00', '#FF285C'];

  const evaluationQuestionCategories = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.getEvaluationQuestionCategoryReducer
      .evaluationQuestionCategories?.results;
  });

  React.useEffect(() => {
    evaluationQuestionCategoryReducer.getQuestionCategories();
  }, []);

  const resultAnalysisForm = useForm<FieldValues & EvaluationResultPayload>({
    defaultValues: {
      root_causes: '',
      strengths: '',
      possible_offerings: [],
      offerings: '',
      challenges_threats: [],
      go_forward_plan: '',
      key_priorities: '',
      development_opportunities: '',
      implementation_details_and_timing: '',
    },
  });

  const { register, control, setValue, handleSubmit, formState: { errors } } = resultAnalysisForm;

  const availableResultData: EvaluationResultAnalysisResponse | null =
    useAppSelector((state) => {
      return state.allEvaluationReducers.getEvaluationResultAnalysisReducer
        .evaluationResultResponse;
    });

  const question_category_string = evaluationQuestionCategories?.find(cat => cat.id === availableResultData?.question_category)?.category || 'Default Category';

  React.useEffect(() => {
    useGetEvaluationAnalysisResultsReducer.evaluationAnalysisResult(
      prop.id,
      prop.evaluationQuestionCategoryId,
    );

    postResultAnalysisSurvey({
      evaluation_id: prop.id,
      question_category_id: prop.evaluationQuestionCategoryId,
    });

    postEvaluationResponseMCAnswers({
      evaluation_id: prop.id,
      question_category_id: prop.evaluationQuestionCategoryId
    });

    postEvaluationResponseTextAnswers({
      evaluation_id: prop.id,
      question_category_id: prop.evaluationQuestionCategoryId
    });
  }, []);

  React.useEffect(() => {
    if (evaluationAnalysisResponse) {
      useGetEvaluationAnalysisResultsReducer.evaluationAnalysisResult(
        prop.id,
        prop.evaluationQuestionCategoryId,
      );
    }
  }, [evaluationAnalysisResponse]);

  React.useEffect(() => {
    if (availableResultData) {
      const possibleOfferingValuesString =
        availableResultData.possible_offerings?.join('\n');
      const challengeThreatsValuesString =
        availableResultData.challenges_threats?.join('\n');
      setValue('strengths', availableResultData.strengths);
      setValue('root_causes', availableResultData.root_causes);
      setValue('challenges', challengeThreatsValuesString);
      setValue('go_forward_plan', availableResultData.go_forward_plan);
      setValue('key_priorities', availableResultData.key_priorities);
      setValue('development_opportunities', availableResultData.development_opportunities);
      setValue('implementation_details_and_timing', availableResultData.implementation_details_and_timing);
      setValue('offerings', possibleOfferingValuesString);
    }
    if (evaluationAnalysisResponse) {
      const rowArray: { key: string; value: number }[] = [];
      for (const key in evaluationAnalysisResponse.overall_scores) {
        const row = {
          key: key,
          value: evaluationAnalysisResponse.overall_scores[key] as number,
        };
        rowArray.push(row);
      }
      setRowOfData(rowArray);
    }
  }, [evaluationAnalysisResponse, availableResultData, setValue]);

  const onSubmit = (data: EvaluationResultPayload) => {
    data.possible_offerings =
      data.offerings && data.offerings.length > 0
        ? data.offerings?.split('\n').filter(i => i)
        : undefined;
    data.offerings = undefined;

    data.challenges_threats =
      data.challenges && data.challenges.length > 0
        ? data.challenges?.split('\n').filter(i => i)
        : undefined;
    data.challenges = undefined;
    usePutEvaluationResultAnalysisReducer.updateEvaluationResultAnalysis({
      params: {
        evaluation_id: prop.id,
        question_category_id: prop.evaluationQuestionCategoryId,
      },
      payload: data,
    });
  };
  const navigate = useNavigate();

  if (
    evaluationAnalysisResponse &&
    evaluationAnalysisResponse.responses_received === 0
  ) {
    toast.error('No response received till now.');
    navigate(`/evaluation-assessment-detail/${prop.id || ''}`);
  }

  return !evaluationAnalysisResponse ||
    !evaluationMCAnswers ||
    !evaluationTextAnswers ||
    !availableResultData ||
    useGetEvaluationAnalysisResultsReducer.loading ||
    useGetEvaluationAnalysisResultsReducer.error ? (
    <CircularProgress sx={{ margin: '20vh 48%' }} />
  ) : evaluationAnalysisResponse.average_evaluation_score === 0 ? (
    <Grid
      container
      sx={{
        backgroundColor: 'var(--primary-light, #F7F9FB)',
        padding: '20px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography14px600>
        {'No question is in this question category.'}
      </Typography14px600>
    </Grid>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        sx={{
          paddingTop: '30px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid item sm={4.7}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'var(--primary-light, #F7F9FB)',
              padding: '30px',
              borderRadius: '12px',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
              }}
              variant="h5"
              component="h2"
              gutterBottom
            >
              {question_category_string}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6} position={'relative'}>
                <Box
                  sx={{
                    height: '210px',
                  }}
                >
                  <PieChart
                    width={400}
                    height={400}
                    style={{
                      position: 'absolute',
                      top: '-80px',
                      left: '-40px',
                    }}
                  >
                    <Pie
                      data={rowsOfData}
                      cx={120}
                      cy={200}
                      startAngle={0}
                      endAngle={360}
                      cornerRadius={50}
                      innerRadius={40}
                      outerRadius={70}
                      stroke="#fff"
                      strokeWidth={5}
                      fill="#8884d8"
                      paddingAngle={-8}
                      dataKey="value"
                    >
                      {rowsOfData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                  </PieChart>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: '-25px',
                    left: '30%',
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container spacing={2}>
                  {rowsOfData.map((eachScore, i) => {
                    return (
                      <React.Fragment key={i}>
                        <GridItemFieldName item xs={9} sm={9}>
                          <Box
                            sx={{
                              width: '6px',
                              height: '6px',
                              borderRadius: '50%',
                              backgroundColor: COLORS[i],
                              float: 'left',
                              marginRight: '5px',
                              position: 'relative',
                            }}
                          ></Box>
                          <FieldName>{eachScore.key}</FieldName>
                        </GridItemFieldName>
                        <GridItemFieldValue item xs={3} sm={3}>
                          <FieldValue> {eachScore.value} </FieldValue>
                        </GridItemFieldValue>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              marginX: '10px',
              marginTop: '10px',
              marginBottom: '30px'
            }}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.score}:
              {evaluationAnalysisResponse.average_evaluation_score}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {translate('evaluation_score_analysis_score_label_subtext', {
                responses_received:
                  evaluationAnalysisResponse.responses_received,
                target_responses: evaluationAnalysisResponse.target_responses,
              })}
            </Typography10px400>
          </Box>

          {availableResultData.evaluation_status !== "Results Review" && (
            <Typography
              sx={{
                color: 'red',
                marginX: '10px',
                marginY: '0px',
                fontSize: '12px'
              }}
            >
              Evaluations must be in the &apos;Results Review&apos; to save any changes made in the sections below.
            </Typography>
          )}

          <Box
            sx={{
              margin: '20px 10px',
              marginTop: '10px'
            }}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.challenges_threats}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {
                translation.evaluation_score_analysis_challenges_threats_label_subtext
              }
            </Typography10px400>

            <Controller
              name="challenges"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  placeholder={
                    translation.evaluation_score_analysis_challenges_threats_placeholder
                  }
                  id="outlined-basic"
                  sx={{
                    width: '90%',
                    marginTop: '10px',
                  }}
                  multiline={true}
                  minRows={6}
                  maxRows={8}
                  InputLabelProps={{
                    style: placeholderText,
                  }}
                  InputProps={{
                    style: innerText,
                  }}
                  {...field}
                />
              )}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.root_cause}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {translation.evaluation_score_analysis_root_cause_label_subtext}
            </Typography10px400>

            <TextField
              placeholder={
                translation.evaluation_score_analysis_root_causes_placeholder
              }
              id="outlined-basic"
              sx={{
                width: '90%',
                marginTop: '10px',
              }}
              multiline={true}
              rows={3}
              InputLabelProps={{
                style: placeholderText,
              }}
              InputProps={{
                style: innerText,
              }}
              {...register('root_causes')}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.strengths}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {translation.evaluation_score_analysis_strengths_label_subtext}
            </Typography10px400>

            <TextField
              placeholder={
                translation.evaluation_score_analysis_strengths_placeholder
              }
              id="outlined-basic"
              sx={{
                width: '90%',
                marginTop: '10px',
              }}
              multiline={true}
              rows={3}
              InputLabelProps={{
                style: placeholderText,
              }}
              InputProps={{
                style: innerText,
              }}
              {...register('strengths')}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.possible_offerings}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {translation.evaluation_score_analysis_offerings_label_subtext}
            </Typography10px400>

            <Controller
              name="offerings"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  placeholder={
                    translation.evaluation_score_analysis_offerings_placeholder
                  }
                  id="outlined-basic"
                  sx={{
                    width: '90%',
                    marginTop: '10px',
                  }}
                  multiline={true}
                  minRows={6}
                  maxRows={8}
                  InputLabelProps={{
                    style: placeholderText,
                  }}
                  InputProps={{
                    style: innerText,
                  }}
                  {...field}
                />
              )}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.go_forward_plan}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {
                translation.evaluation_score_analysis_go_forward_plan_cause_label_subtext
              }
            </Typography10px400>

            <TextField
              placeholder={
                translation.evaluation_score_analysis_go_forward_plan_placeholder
              }
              id="outlined-basic"
              sx={{
                width: '90%',
                marginTop: '10px',
              }}
              multiline={true}
              rows={3}
              InputLabelProps={{
                style: placeholderText,
              }}
              InputProps={{
                style: innerText,
              }}
              {...register('go_forward_plan')}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.key_priorities}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {
                translation.evaluation_score_analysis_key_priorities_cause_label_subtext
              }
            </Typography10px400>

            <TextField
              placeholder={
                translation.evaluation_score_analysis_key_priorities_placeholder
              }
              id="outlined-basic"
              sx={{
                width: '90%',
                marginTop: '10px',
              }}
              multiline={true}
              rows={3}
              InputLabelProps={{
                style: placeholderText,
              }}
              InputProps={{
                style: innerText,
              }}
              {...register('key_priorities', {
                minLength: {
                  value: 20,
                  message: 'Key priorities must be at least 20 characters long'
                }
              })}
              error={!!errors.key_priorities}
              helperText={errors.key_priorities ? errors.key_priorities.message : ''}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.development_opportunities}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {
                translation.evaluation_score_analysis_development_opportunities_cause_label_subtext
              }
            </Typography10px400>

            <TextField
              placeholder={
                translation.evaluation_score_analysis_development_opportunities_placeholder
              }
              id="outlined-basic"
              sx={{
                width: '90%',
                marginTop: '10px',
              }}
              multiline={true}
              rows={3}
              InputLabelProps={{
                style: placeholderText,
              }}
              InputProps={{
                style: innerText,
              }}
              {...register('development_opportunities', {
                minLength: {
                  value: 20,
                  message: 'Development Opportunities must be at least 20 characters long'
                }
              })}
              error={!!errors.development_opportunities}
              helperText={errors.development_opportunities ? errors.development_opportunities.message : ''}
            />
          </Box>

          <Box margin={'20px 10px'}>
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.implementation_details_and_timing}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {
                translation.evaluation_score_analysis_implementation_details_and_timing_cause_label_subtext
              }
            </Typography10px400>

            <TextField
              placeholder={
                translation.evaluation_score_analysis_implementation_details_and_timing_placeholder
              }
              id="outlined-basic"
              sx={{
                width: '90%',
                marginTop: '10px',
              }}
              multiline={true}
              rows={3}
              InputLabelProps={{
                style: placeholderText,
              }}
              InputProps={{
                style: innerText,
              }}
              {...register('implementation_details_and_timing', {
                minLength: {
                  value: 20,
                  message: 'Implementation Details and Timing must be at least 20 characters long'
                }
              })}
              error={!!errors.implementation_details_and_timing}
              helperText={errors.implementation_details_and_timing ? errors.implementation_details_and_timing.message : ''}
            />
          </Box>

          <Grid
            item
            sm={12}
            sx={{
              margin: '30px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              type="submit"
              sx={{
                color: '#fff',
                minWidth: '70px',
                fontSize: '15px'
              }}
              //disabled={availableResultData.evaluation_status !== "Results Review"}
            >
              Save
            </CustomButton>
          </Grid>
        </Grid>

        <Grid item sm={7}>
          <Typography12px400
            sx={{
              color: '#181C32',
              paddingTop: '0px',
              paddingBottom: '10px',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Likert Scale Question Answer Averages
          </Typography12px400>
          <Grid
            container
            sx={{
              backgroundColor: 'var(--primary-light, #F7F9FB)',
              padding: '20px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {evaluationAnalysisResponse.individual_answer_score.map(
              (eachQuestionObj, i) => {
                return (
                  <React.Fragment key={i}>
                    <Grid
                      item
                      sx={{
                        margin: '10px',
                      }}
                      sm={7}
                    >
                      <Typography12px400
                        sx={{
                          lineHeight: '20px',
                        }}
                      >
                        {eachQuestionObj.question}
                      </Typography12px400>
                    </Grid>
                    <Grid item key={i} sm={4}>
                      <StaticLikertScale value={Math.round(eachQuestionObj.score * 10) / 10} />
                    </Grid>
                  </React.Fragment>
                );
              },
            )}
          </Grid>

          {
            evaluationMCAnswers.questions_and_answers.length > 0 && (
              <>
                <Typography12px400
                  sx={{
                    color: '#181C32',
                    paddingTop: '30px',
                    paddingBottom: '0px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  Yes/No Question Answer Averages
                </Typography12px400>
                <Grid
                  container
                  sx={{
                    backgroundColor: 'var(--primary-light, #F7F9FB)',
                    padding: '20px',
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}
                >
                  {evaluationMCAnswers.questions_and_answers.map((eachQuestionObj, i) => (
                    <React.Fragment key={i}>
                      <Grid item sx={{ margin: '10px' }} sm={7}>
                        <Typography12px400 sx={{ lineHeight: '20px' }}>
                          {eachQuestionObj.question_text}
                        </Typography12px400>
                      </Grid>
                      <Grid item sm={4}>
                        <StaticLikertScale value={Math.round(eachQuestionObj.average_score * 10) / 10} />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )
          }

          {
            evaluationTextAnswers.questions_and_answers.length > 0 && (
              <>
                <Typography12px400
                  sx={{
                    color: '#181C32',
                    paddingTop: '30px',
                    paddingBottom: '0px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  Text Entry Question Answers
                </Typography12px400>
                <Grid
                  container
                  sx={{
                    backgroundColor: 'var(--primary-light, #F7F9FB)',
                    padding: '20px',
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}
                >
                  {evaluationTextAnswers.questions_and_answers.map((eachQuestionObj, i) => (
                    <React.Fragment key={i}>
                      <Grid item sx={{ margin: '10px' }} sm={7}>
                        <Typography12px400 sx={{ lineHeight: '20px' }}>
                          {eachQuestionObj.question_text}
                        </Typography12px400>
                      </Grid>
                      <Grid item key={i} sm={4}>
                        {/* Display each answer in a scrollable text box */}
                        {eachQuestionObj.answers.map((answer, index) => (
                          <Box
                            key={index}
                            sx={{
                              maxHeight: '150px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              padding: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography12px400 sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                              {answer.answer_text}
                            </Typography12px400>
                          </Box>
                        ))}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )
          }
        </Grid>
      </Grid>
    </form>
  );
};

export default EvaluationAnalysis;
