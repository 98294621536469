import React from 'react';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
export default function RowRadioButtonsGroup({
  question,
  options,
  formData,
  setFormData,
}: {
  question?: any;
  options?: any;
  formData?: any;
  setFormData?: any;
}) {
  const handleChange = (event: React.ChangeEvent, v: string) => {
    setFormData({ ...formData, [question.id]: v });
  };

  return (
    <FormControl sx={{ marginTop: '20px', marginLeft: '40px' }}>
      <RadioGroup
        value={formData[question.id] || ''}
        onChange={handleChange}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {options?.map((option, i: number) => (
          <FormControlLabel
            key={i}
            value={option.option}
            control={<Radio />}
            label={option.option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
