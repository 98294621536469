import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { EvaluationSurveyPayload } from '../type';
import { useState } from 'react';
import { postEvaluationSurvey } from '../reducers/postEvaluationSurvey';
import { useNavigate } from 'react-router-dom';

const usePostEvalutaionSurvey = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const navigate = useNavigate();

  const postSurvey = async (
    id: string,
    requestPayload: EvaluationSurveyPayload,
  ) => {
    try {
      const resultAction = await dispatch(
        postEvaluationSurvey({ id, requestPayload }),
      );
      if (postEvaluationSurvey.fulfilled.match(resultAction)) {
        setLoading(false);
        navigate('/success-evaluation-assessment');
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postSurvey };
};

export default usePostEvalutaionSurvey;
