/* eslint-disable indent */

import React, { useEffect, useState } from 'react';
import {
  Form,
  FieldContainer,
  SubTitleTypography,
  TitleTypography,
} from '../CreateEvaluation/CreateEvaluation.style';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import translation from '../../../locales/en/translation.json';
import {
  CancelButton,
} from '../../Accounts/CreateClient/CreateClient.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  Controller,
  FieldValues,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  CreateEvaluationType,
  EvaluationCategory,
  UpdateEvaluationType,
} from '../type';
import moment from 'moment';
import CloseIcon from '../../../assets/svg-components/CloseIcon';
import {
  CustomButton,
  CustomCheckBox,
  ErrorMessage,
  Typography12px400,
  Typography14px400,
  Typography14px500,
  Typography18px500,
} from '../../../styles/Global.styles';
import useGetEvaluationCategories from '../hooks/useGetEvaluationCategories';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../redux/store.type';
import { AccountDetails } from '../../Accounts/types';
import useGetClients from '../../Accounts/hooks/useGetClients';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetAccountSettingsUsers from '../hooks/useGetAccountSettingUser';
import useGetEvaluationDetail from '../hooks/useGetEvaluationDetail';
import CheckIcon from '@mui/icons-material/Check';
import usePutEvaluation from '../hooks/usePutEvaluation';
import useDebounce from '../../../hooks/useDebounce';
import { clearEvaluationDetail } from '../reducers/getEvaluationDetail';
import { clearClientList } from '../../Accounts/reducers/getClientList';
import { store } from '../../../redux/store';
import { useFormLabelsTheme } from '../../../hooks/useFormLabelsTheme';

const EditEvaluation = () => {
  const formLabelsTheme = useFormLabelsTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const baseUrl = '';
  const getEvaluationDetailReducer = useGetEvaluationDetail();
  const updateEvaluationReducer = usePutEvaluation();
  const evaluationDetailInfo = useSelector((state: RootStoreType) => {
    return state.allEvaluationReducers.evaluationDetailReducer.evaluationDetail
      ?.results[0];
  });
  const [accountObject, setAccountObject] =
    React.useState<AccountDetails | null>(null);

  useEffect(() => {
    getEvaluationDetailReducer.getEvaluationDetailInfo(id || '');
  }, []);

  const [isAutoEmailReminderActive, setIsAutoEmailReminderActive] =
    useState(false);
  const form = useForm<FieldValues & CreateEvaluationType>({
    defaultValues: {
      closed_date: null,
      account_id: '',
      evaluation_category: '',
      evaluation_name: '',
      shareable_link: baseUrl + `${evaluationDetailInfo?.shareable_link || ''}`,
      target_responses: 0,
      days_before_close_date: null,
      is_auto_email_reminder_active: isAutoEmailReminderActive,
      recipients: [{ notify_email: '' }],
      account: accountObject,
    },
  });

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
  } = form;

  const evaluationCategoryResponse = useGetEvaluationCategories();
  const accountsResponse = useGetClients();
  const { debounce } = useDebounce(1000);

  const evaluationId = watch('evaluation_category');
  const recipients = watch('recipients'); // This will subscribe to changes of `recipients`
  const [evaluationName, setEvaluationName] = useState<string>('');
  const [isAnyFieldTouched, setAnyFieldTouched] = useState<boolean>(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const evaluationCategories: EvaluationCategory[] | null = useSelector(
    (state: RootStoreType) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.getEvaluationCategoriesReducer
        .evaluationCategories;
    },
  );

  const companyList: AccountDetails[] | undefined = useSelector(
    (state: RootStoreType) => {
      return (
        state.allClientReducer.clientListReducer.accountListResponse?.results ||
        []
      );
    },
  );

  useEffect(() => {
    setValue('target_responses', recipients?.length || 0);
  }, [recipients, setValue]);

  const [inputValue, setInputValue] = React.useState('');
  useEffect(() => {
    if (evaluationDetailInfo && companyList.length > 0) {
      const accountWithEvaluation = companyList.find(
        (company) => company.name === evaluationDetailInfo.account_name,
      );
      if (accountWithEvaluation) {
        getAccountSettingsUsers(accountWithEvaluation.id.toString());
        setAccountObject(accountWithEvaluation);
      }
    }
  }, [evaluationDetailInfo, companyList]);

  const { fields, append, remove } = useFieldArray<
    FieldValues & CreateEvaluationType
  >({
    name: 'recipients',
    control,
  });

  useEffect(() => {
    if (evaluationDetailInfo) {
      accountsResponse.clientList({
        search: evaluationDetailInfo.account_name,
      });
    }
  }, [evaluationDetailInfo]);

  useEffect(() => {
    if (
      companyList.length > 0 &&
      evaluationDetailInfo &&
      companyList.find(
        (company) => company.name === evaluationDetailInfo.account_name,
      )
    ) {
      setAccountObject(
        companyList.find((company) => {
          company.name === evaluationDetailInfo.account_name;
        }) || null,
      );
    }
  }, []);

  useEffect(() => {
    if (accountObject && evaluationId) {
      const nameOfAccount = companyList.find(
        (company) => company.id.toString() == accountObject.id.toString(),
      )?.name;
      const nameOfEvaluation =
        evaluationCategories &&
        evaluationCategories.find((evaluation) => evaluation.id == evaluationId)
          ?.name;
      if (nameOfAccount && nameOfEvaluation && isAnyFieldTouched) {
        setEvaluationName(nameOfAccount + ' ' + nameOfEvaluation);
        clearErrors('evaluation_name');
      }
    }
  }, [accountObject, evaluationId, setValue, clearErrors]);

  useEffect(() => {
    setValue('evaluation_name', evaluationName);
  }, [evaluationName]);

  const onFormSubmit = (data: CreateEvaluationType) => {
    setSubmitButtonClicked(true);
    if (accountObject) {
      const payload: UpdateEvaluationType = {
        evaluation_id: id!,
        ...data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        recipients_list: data.recipients
          ? data.recipients
              .filter((email_address) => email_address.notify_email !== '')
              .map((item) => item.notify_email && item.notify_email)
          : [],
        closed_date: moment(data.closed_date, 'MM/DD/YYYY', true).format(
          'YYYY-MM-DD',
        ),
        shareable_link: data.shareable_link.includes('/evaluate/')
          ? data.shareable_link.split('/evaluate/')[1]
          : data.shareable_link,
        account_id: accountObject.id.toString(),
      };
      if(!updateEvaluationReducer.loading){
        updateEvaluationReducer.updateEvaluation(payload);
      }
    }
  };

  const [selectedEvaluationId, setSelectedEvaluationId] = useState<string>('');

  const { getAccountSettingsUsers } = useGetAccountSettingsUsers();

  const allAccountUsers = useSelector((state: RootStoreType) => {
    return state.allClientReducer?.accountSettingReducer?.accountSetting;
  });

  useEffect(() => {
    if (selectedEvaluationId) {
      setValue('evaluation_category', selectedEvaluationId);
      clearErrors('evaluation_category');
    }
  }, [selectedEvaluationId]);

  useEffect(() => {
    if (evaluationDetailInfo) {
      // setValue('account_id', evaluationDetailInfo.account_id);
      setValue(
        'evaluation_category',
        evaluationDetailInfo.evaluation_category_id,
      );
      setValue('closed_date', moment(evaluationDetailInfo.closed_date));
      setValue('evaluation_name', evaluationDetailInfo.evaluation_name);
      setValue('shareable_link', baseUrl + evaluationDetailInfo.shareable_link);
      setValue('target_responses', evaluationDetailInfo.target_responses);

      setIsAutoEmailReminderActive(
        evaluationDetailInfo.is_auto_email_reminder_active,
      );
      setValue(
        'days_before_close_date',
        evaluationDetailInfo.days_before_close_date,
      );
      setValue('account', accountObject || null);
      setEvaluationName(evaluationDetailInfo.evaluation_name);
      const allRecipients = evaluationDetailInfo.recipients_list.map(
        (eachRecipient) => {
          return { notify_email: eachRecipient };
        },
      );
      setValue(
        'recipients',
        allRecipients.length > 0 ? allRecipients : [{ notify_email: '' }],
      );
    }
  }, [evaluationDetailInfo]);

  function shouldShowForm(): boolean {
    return (
      !!evaluationDetailInfo &&
      !evaluationCategoryResponse.error &&
      !evaluationCategoryResponse.loading
    );
  }

  return (
    <>
      <Box>{evaluationDetailInfo && evaluationDetailInfo.evaluation_name}</Box>
      {!shouldShowForm() ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
      ) : (
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <ThemeProvider theme={formLabelsTheme}>
            <Grid
              container
              sx={{
                flexWrap: 'wrap-reverse',
              }}
            >
              <Grid item sm={7} xs={10}>
                <TitleTypography marginBottom={'34px'}>
                  {translation.general_info}
                </TitleTypography>
                <Box sx={{ marginBottom: '70px' }}>
                  <FieldContainer>
                    <FormLabel required>{translation.account_name}</FormLabel>
                    <Autocomplete
                      id="free-solo-demo"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          padding: '0',
                        },
                      }}
                      value={accountObject}
                      onChange={(_, newAccount) => {
                        if (newAccount) {
                          setAnyFieldTouched(true);
                          setAccountObject(newAccount);
                          getAccountSettingsUsers(newAccount.id.toString());
                        }
                      }}
                      inputValue={inputValue}
                      onInputChange={(_, newInputValue) => {
                        if (!newInputValue) {
                          setAccountObject(null);
                        }
                        debounce(() => {
                          accountsResponse.clientList({
                            search: newInputValue,
                          });
                        });
                        setInputValue(newInputValue);
                      }}
                      disablePortal
                      options={companyList}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(opt, val) => {
                        return opt.id === val.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Account By Name"
                        />
                      )}
                    />

                    {!accountObject && submitButtonClicked && (
                      <ErrorMessage>
                        {translation.account_name_is_required}
                      </ErrorMessage>
                    )}
                  </FieldContainer>
                </Box>

                <TitleTypography marginBottom={'34px'}>
                  {translation.configuration}
                </TitleTypography>
                <Box>
                  <FieldContainer>
                    <FormLabel required>
                      {translation.evaluation_type}
                    </FormLabel>
                    <Controller
                      name="evaluation_category"
                      control={control}
                      rules={{
                        required: translation.evaluation_type_is_required,
                      }}
                      render={({ field }) => (
                        <Select
                          size="small"
                          displayEmpty
                          defaultValue={''}
                          placeholder={translation.select_an_option}
                          {...field}
                          onChange={(event) => {
                            setAnyFieldTouched(true);
                            setSelectedEvaluationId(event.target.value);
                          }}
                        >
                          {evaluationCategories &&
                            evaluationCategories.map((category) => {
                              return (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                    {errors.evaluation_category && (
                      <ErrorMessage>
                        {errors.evaluation_category.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FieldContainer>
                </Box>

                <Box>
                  <FieldContainer>
                    <FormLabel required>
                      {translation.evaluation_name}
                    </FormLabel>
                    <Controller
                      name="evaluation_name"
                      control={control}
                      rules={{
                        required: translation.evaluation_name_is_required,
                      }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          {...field}
                          value={evaluationName}
                          onChange={(event) => {
                            field.onChange(event);
                            setEvaluationName(event.target.value);
                          }}
                        />
                      )}
                    />
                    {errors.evaluation_name && (
                      <ErrorMessage>
                        {errors.evaluation_name.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FieldContainer>
                </Box>

                <Box marginBottom={'70px'}>
                  <FieldContainer>
                    <FormLabel required>{translation.close_date}</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Controller
                        control={control}
                        name="closed_date"
                        rules={{ required: translation.close_date_is_required }}
                        render={({ field }) => (
                          <DatePicker sx={{ height: '50px' }} {...field} />
                        )}
                      />
                    </LocalizationProvider>
                    {errors.closed_date && (
                      <ErrorMessage>
                        {errors.closed_date.message?.toString()}
                      </ErrorMessage>
                    )}
                  </FieldContainer>
                </Box>
              </Grid>
              <Grid item sm={5} xs={10}>
                {allAccountUsers &&
                allAccountUsers.filter(
                  (user) => user.role == 'account_executive',
                ).length < 1 ? (
                  <Typography14px500
                    sx={{
                      color: '#B5B5C3',
                      paddingBottom: '20px',
                    }}
                  >
                    {translation.no_AE_available_text}
                  </Typography14px500>
                ) : (
                  allAccountUsers &&
                  allAccountUsers
                    .filter((user) => user.role == 'account_executive')
                    .map((user) => {
                      return (
                        <React.Fragment key={user.id}>
                          <Box marginBottom={'10px'}>
                            <Typography12px400
                              sx={{
                                color: '#181C32',
                              }}
                            >
                              {translation.account_executive}
                            </Typography12px400>
                          </Box>

                          <Box
                            key={user.id}
                            sx={{
                              display: 'flex',
                              gap: '20px',
                              margin: '15px 0',
                            }}
                          >
                            <Avatar
                              sx={{
                                height: '50px',
                                width: '50px',
                              }}
                            >
                              {user.fullname[0]}
                            </Avatar>
                            <Box>
                              <Typography18px500
                                sx={{
                                  color: '#464E5F',
                                  paddingBottom: '10px',
                                }}
                              >
                                {user.fullname}
                              </Typography18px500>
                              <Typography12px400
                                sx={{
                                  color: '#717986',
                                }}
                              >
                                Business Development Officer
                              </Typography12px400>
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    })
                )}

                {allAccountUsers &&
                  allAccountUsers
                    .filter((user) => user.role == 'support')
                    .map((user, index) => {
                      return (
                        user.role == 'support' && (
                          <React.Fragment key={user.id}>
                            {index == 0 && (
                              <Box marginBottom={'10px'}>
                                <Typography12px400
                                  sx={{
                                    color: '#181C32',
                                  }}
                                >
                                  {translation.support}
                                </Typography12px400>
                              </Box>
                            )}

                            <Box
                              key={user.id}
                              sx={{
                                display: 'flex',
                                gap: '20px',
                                margin: '15px 0',
                              }}
                            >
                              <Avatar
                                sx={{
                                  height: '50px',
                                  width: '50px',
                                }}
                              >
                                {user.fullname[0]}
                              </Avatar>
                              <Box>
                                <Typography18px500
                                  sx={{
                                    color: '#464e5f',
                                    paddingBottom: '10px',
                                  }}
                                >
                                  {user.fullname}
                                </Typography18px500>
                                <Typography12px400
                                  sx={{
                                    color: '#717986',
                                  }}
                                >
                                  Operational Manager
                                </Typography12px400>
                              </Box>
                            </Box>
                          </React.Fragment>
                        )
                      );
                    })}
              </Grid>
            </Grid>

            <Box marginBottom={'70px'}>
              <TitleTypography>{translation.shareable_link}</TitleTypography>
              <SubTitleTypography marginBottom={'25px'}>
                {translation.evaluation_shareable_link_subtext}
              </SubTitleTypography>

              <FieldContainer>
                <FormLabel>{translation.evaluation_link}</FormLabel>
                <Controller
                  name="shareable_link"
                  control={control}
                  rules={{
                    required: 'evaluation_link_is_required',
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      sx={{ width: '75%' }}
                      {...field}
                    />
                  )}
                />
              </FieldContainer>
            </Box>

            <Box marginBottom={'70px'}>
              <TitleTypography>{translation.direct_recipient}</TitleTypography>
              <SubTitleTypography marginBottom={'25px'}>
                {translation.evaluation_direct_recipient_subtext}
              </SubTitleTypography>
              <FormLabel>{translation.user_email}</FormLabel>
              {fields.map((field, index) => {
                return (
                  <div key={field.id}>
                    <FieldContainer sx={{ marginBottom: '10px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0 5px',
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          sx={{ width: '75%' }}
                          {...register(`recipients.${index}.notify_email`, {
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: translation.invalid_email,
                            },
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {index != 0 && (
                                  <Box
                                    component={'span'}
                                    onClick={() => remove(index)}
                                    sx={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <CloseIcon />
                                  </Box>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {errors.recipients &&
                        errors.recipients[index]?.notify_email && (
                          <ErrorMessage>
                            {errors.recipients[
                              index
                            ]?.notify_email?.message?.toString()}
                          </ErrorMessage>
                        )}
                    </FieldContainer>
                    {index === fields.length - 1 && (
                      <Typography14px400
                        marginLeft={'5px'}
                        sx={{
                          color: '#1C1C1C',
                          lineHeight: '20px',
                          marginLeft: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => append({ notify_email: '' })}
                      >
                        + Add Recipient
                      </Typography14px400>
                    )}
                  </div>
                );
              })}
            </Box>

            <Box marginBottom={'70px'}>
              <TitleTypography>{translation.notification}</TitleTypography>
              <SubTitleTypography marginBottom={'25px'}>
                {translation.evaluation_notification_subtext}
              </SubTitleTypography>
              <FieldContainer>
                <FormLabel>{translation.target_responses}</FormLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{ width: '58.5%' }}
                  {...register(`target_responses`, {
                    pattern: {
                      value: /^\d*\d+$/,
                      message: 'Only positive numbers are allowed.',
                    },
                  })}
                />
                {errors.target_responses && (
                  <ErrorMessage>
                    {errors.target_responses.message?.toString()}
                  </ErrorMessage>
                )}
              </FieldContainer>
            </Box>

            <Box>
              <TitleTypography>{translation.reminder}</TitleTypography>
              <SubTitleTypography marginBottom={'25px'}>
                {translation.evaluation_reminder_subtext}
              </SubTitleTypography>
              <FieldContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CustomCheckBox />}
                      checkedIcon={
                        <CustomCheckBox
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fill: 'black',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                        >
                          <CheckIcon />
                        </CustomCheckBox>
                      }
                      {...register('is_auto_email_reminder_active')}
                      size="small"
                      checked={isAutoEmailReminderActive}
                      onChange={() =>
                        setIsAutoEmailReminderActive(!isAutoEmailReminderActive)
                      }
                    />
                  }
                  sx={{
                    fontSize: '10px',
                  }}
                  label={
                    <FormLabel>{translation.send_reminder_email}</FormLabel>
                  }
                />
              </FieldContainer>
              <FieldContainer>
                <FormLabel>{translation.days_before_close_date}</FormLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{ width: '58.5%' }}
                  {...register(`days_before_close_date`, {
                    pattern: {
                      value: /^\d*\d+$/,
                      message: 'Only positive numbers are allowed.',
                    },
                  })}
                />
                {errors.days_before_close_date && (
                  <ErrorMessage>
                    {errors.days_before_close_date.message?.toString()}
                  </ErrorMessage>
                )}
              </FieldContainer>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: '20px',
              }}
            >
              <CustomButton
                sx={{
                  width: '150px',
                }}
                type="submit"
                variant="contained"
                size="medium"
              >
                Update
              </CustomButton>
              <CancelButton
                sx={{
                  width: '150px',
                  marginLeft: '12px',
                }}
                onClick={() => {
                  store.dispatch(clearEvaluationDetail());
                  store.dispatch(clearClientList());
                  navigate('/evaluation-assessment-list');
                }}
              >
                Cancel
              </CancelButton>
            </Box>
          </ThemeProvider>
        </Form>
      )}
    </>
  );
};

export default EditEvaluation;
