import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';

const initialState = {
  status: RequestStatus.Init,
};

const getEvaluationReportSlice = createSlice({
  name: '/generateReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationReportThunk.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationReportThunk.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      toast.success('Report is generated');
    });
    builder.addCase(getEvaluationReportThunk.rejected, (state) => {
      state.status = RequestStatus.Error;
      toast.error('Report is not generated');
    });
  },
});

export const getEvaluationReportThunk = createAsyncThunk<string, string>(
  '/getReport',
  async (evaluation_id, thunkAPI) => {
    try {
      const response: AxiosResponse<string> = await evaluationReport(
        evaluation_id,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const evaluationReport = async (
  evaluation_id: string,
): Promise<AxiosResponse<string>> => {
  return await axiosInstance.get(EndPoints.GET_EVALUATION_REPORT, {
    params: { evaluation_id, download: true },
  });
};

export default getEvaluationReportSlice.reducer;
