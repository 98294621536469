import { useDispatch } from 'react-redux';
import { createClient } from '../reducers/createClient';
import { CreateClientRequest } from '../types';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';

interface useCreateClientProps {
  error:unknown,
  loading:boolean,
  createAccount: (createClientRequest: CreateClientRequest) => Promise<void>;
}

const useCreateClient = (): useCreateClientProps => {
  const [loading,setLoading] = useState(false)
  const [error,setError] = useState<unknown>()
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const createAccount = async (createClientRequest: CreateClientRequest) => {
    try {
      setLoading(true)
      const resultAction = await dispatch(createClient(createClientRequest));
      if (createClient.fulfilled.match(resultAction)) {
        setLoading(false)
        navigate('/account-list');
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { createAccount,loading,error };
};

export default useCreateClient;
