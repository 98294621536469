import { useDispatch } from 'react-redux';
import { getEvaluationImageUrl } from '../reducers/getEvaluationImageUrl';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { AxiosError } from 'axios';


const useGetEvaluationImageUrl = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const getImageDetails = async (
    evaluationIdRequestPayload: string,
  ) => {
    const resultAction = await dispatch(
      getEvaluationImageUrl(evaluationIdRequestPayload),
    ).then((res) => {
      if (getEvaluationImageUrl.fulfilled.match(res)) {
        setLoading(false);
      }
      if (getEvaluationImageUrl.rejected.match(res)) {
        setLoading(false);
        setError(res.payload as AxiosError);
      }
    });
  };

  return { evaluationImageUrlError: error, evaluationImageUrlLoading: loading, getImageDetails };
};

export default useGetEvaluationImageUrl;
